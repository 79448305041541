const initialState = {
  loading: false,
  error: undefined
}

const app = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_APP_LOADING': return { ...state, loading: action.payload }
    case 'SET_APP_ERROR': return { ...state, error: action.payload }
    default: return state
  }
}

export default app
