import React from 'react'
import { setAppError } from '../store/actions/app-actions'
import './error-view.styles.scss'
import { connect } from 'react-redux'
import TimesIcon from '../resources/icons/times'

type Props = {
  error?: Error
  clearError(): void
}

export function ErrorView(props: Props) {
  if (!props.error || !(props.error instanceof Error)) {
    return <></>
  }

  return (
    <div className="error-view">
      <button className="action" onClick={props.clearError}>
        <TimesIcon />
      </button>
      <div className="title">
        <p>Oops, aconteceu um erro</p>
      </div>
      <p className="message">{props.error.message}</p>
    </div>
  )
}

const mapState = (state) => ({
  error: state.app.error
})

const mapDispatch = (dispatch) => ({
  clearError: () => dispatch(setAppError())
})

export default connect(mapState, mapDispatch)(ErrorView)
