import React from 'react'

export default function UploadFileSuccess() {
  return (
    <div className="page">
      <div className="content">
        <h1 className="page-title">Sucesso!</h1>

        <p>Vamos enviar um relatório por e-mail quando tudo estiver pronto.</p>

        <p>
          Caso houver algum problema, acesse o link que mandamos para você e anexe um novo arquivo
          CSV.
        </p>
      </div>
    </div>
  )
}
