import { createFetcher } from './create-fetcher'

/**
 *
 * @returns {Promise<ConnectorResponse | undefined>}
 */
export async function getConnectors() {
  const fetch = createFetcher()

  const response = await fetch.get('/connectors')
  if (response.status === 200 || response.statusText === 'OK') return response.data
}
