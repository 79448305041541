import axios from 'axios'
import { API_URL, AUTH_TOKEN } from '../env/api'

export const createFetcher = () => {
  return axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${AUTH_TOKEN}`
    }
  })
}
