import { createFetcher } from './create-fetcher'

/**
 *
 * @param params
 * @returns {Promise<EcommerceResponse | undefined>}
 */
export const postEcommerce = async (params) => {
  const fetch = createFetcher()
  const response = await fetch.post('/ecommerces', params)
  if (response.status === 200 || response.statusText === 'OK') return response.data
}

export const postEcommerceFile = async ({ ecommerce, formData }) => {
  const fetch = createFetcher()
  const response = await fetch.post(`/files/${ecommerce}`, formData)
  if (response.status === 200 || response.statusText === 'OK') return response.data
}
