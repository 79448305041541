import { call, put } from 'redux-saga/effects'
import { setAppError } from '../actions/app-actions'

export default function* request(service, ...params) {
  try {
    const response = yield call(service, ...params)
    return response
  } catch (err) {
    if (err.message || (err.response && err.response.data && err.response.data.message)) {
      if (err.response.data.message === 'Connector not found') {
        yield put(
          setAppError(
            new Error(`
          Configurações do conector não encontradas,
          por favor entre em contato com o nosso suporte.`)
          )
        )
      } else {
        yield put(setAppError(new Error('Verifique se os dados no arquivo estão corretos.')))
      }
    } else {
      yield put(setAppError(new Error('Verifique se os dados no arquivo estão corretos.')))
    }

    return
  }
}
