import { put } from 'redux-saga/effects'
import { postEcommerce, postEcommerceFile } from '../../services/ecommerces'
import { setAppLoading } from '../actions/app-actions'
import request from './request-saga'

export function* asyncPostEcommerce(action) {
  const response = yield request(postEcommerce, action.payload)

  if (response && response.url) {
    window.location.href = response.url
  }
}

export function* asyncPostEcommerceFile(action) {
  const formData = new FormData()
  formData.append('file', action.payload.file)

  yield put(setAppLoading(true))
  const response = yield request(postEcommerceFile, {
    ecommerce: action.payload.ecommerce,
    formData
  })
  yield put(setAppLoading(false))

  if (response) {
    window.location.href = `/edi/${action.payload.ecommerce}/success`
  }
}

asyncPostEcommerce({ type: '', action: {} }).next()
