import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import RegisterEcommercePage from './register-ecommerce-page'
import UploadFilePage from './upload-file-page'
import UploadFileSuccess from './upload-file-success-page'

export default function PageRouter() {
  return (
    <BrowserRouter>
      <>
        <Switch>
          <Route path="/" exact>
            <RegisterEcommercePage />
          </Route>
          <Route path="/edi/:code" exact>
            <UploadFilePage />
          </Route>
          <Route path="/edi/:code/success" exact>
            <UploadFileSuccess />
          </Route>
        </Switch>
      </>
    </BrowserRouter>
  )
}
