import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './reducers/root-reducer'
import rootSaga from './sagas/root-saga'

export const create = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware({
    onError(err) {
      console.error(err)
    }
  })

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    createRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

  sagaMiddleware.run(rootSaga)

  return store
}
