import { combineReducers } from 'redux'
import app from './app-reducer'
import connectors from './connectors-reducer'
import ecommerce from './ecommerces-reducer'

const createRootReducer = (history) =>
  combineReducers({
    app,
    connectors,
    ecommerce
  })

export default createRootReducer
