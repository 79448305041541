import React from 'react'
import './file-list-item.styles.scss'
import TimesIcon from '../resources/icons/times'

type Props = {
  file: File
  onExclude(file: File): void
}

export default function FileListItem(props: Props) {
  return (
    <li className="file-list-item">
      <p className="name">{props.file.name}</p>
      <button className="action" type="button" onClick={() => props.onExclude(props.file)}>
        <TimesIcon />
      </button>
    </li>
  )
}
