export const getConnectors = () => ({
  type: 'ASYNC_GET_CONNECTORS'
})

export const setConnectors = (connectors) => ({
  type: 'SET_CONNECTORS',
  payload: connectors
})

export const setSelectedConnector = (connectorId) => ({
  type: 'SET_SELECTED_CONNECTOR',
  payload: connectorId
})
