import React, { useState } from 'react'
import FileAttach from '../components/file-attach'
import './page.styles.scss'
import ErrorView from '../components/error-view'
import { uploadFile } from '../store/actions/ecommerces'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { useParams } from 'react-router-dom'

export function UploadFilePage(props) {
  const [files, setFiles] = useState([])
  const { code } = useParams()
  const disabled = !files || files.length === 0

  const submit = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    const payload = {
      ecommerce: code,
      file: files[0]
    }

    props.upload(payload)
  }

  return (
    <div className="page">
      <div className="content">
        <form className="form-container" onSubmit={submit}>
          <h1 className="page-title">Anexo de arquivo de EDI</h1>

          <p className="page-message">
            Iremos usar este arquivo para sincronizar dados de rastreio de pedido com a sua
            plataforma de E-commerce
          </p>

          <h3 className="page-subtitle">Templates</h3>

          <p className="page-message">Por favor, utilize algum dos templates abaixo</p>
          <a className="link" href="/public_files/importacao.csv" download="importacao.csv">
            Template CSV
          </a>
          <a className="link" href="/public_files/importacao.xlsx" download="importacao.xlsx">
            Template XLSX
          </a>

          <FileAttach id="uploader" label="Anexe um arquivo" onChange={setFiles} files={files} />

          <ErrorView />

          <div className="form-actions">
            <button className={`button ${disabled ? 'disabled' : 'primary'}`} type="submit">
              Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

UploadFilePage.propTypes = {
  upload: func
}

const mapDispatch = (dispatch) => ({
  upload: (payload) => dispatch(uploadFile(payload))
})

export default connect(null, mapDispatch)(UploadFilePage)
