export const postEcommerce = (payload) => ({
  type: 'ASYNC_POST_ECOMMERCE',
  payload
})

export const setEcommerce = (ecommerce) => ({
  type: 'SET_ECOMMERCE',
  payload: ecommerce
})

export const setUploaderUrl = (url) => ({
  type: 'SET_UPLOADER_URL',
  payload: url
})

export const uploadFile = (payload) => ({
  type: 'ASYNC_ECOMMERCE_UPLOAD_FILE',
  payload
})
