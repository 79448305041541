const initialState = {
  connectors: undefined,
  selected: undefined
}

const connectors = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONNECTORS':
      return { ...state, connectors: action.payload }
    case 'SET_SELECTED_CONNECTOR':
      return { ...state, selected: state.connectors.find((conn) => conn.id === action.payload) }
    default:
      return state
  }
}

export default connectors
