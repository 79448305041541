import { call, put } from 'redux-saga/effects'
import { getConnectors } from '../../services/connectors'
import { setConnectors } from '../actions/connectos'

export function* asyncGetConnectors() {
  const response = yield call(getConnectors)

  if (response) {
    yield put(setConnectors(response))
  }
}
