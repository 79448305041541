const initialState = {
  ecommerces: undefined,
  uploaderUrl: undefined
}

const ecommerce = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ECOMMERCE':
      return { state, ecommerces: action.payload }
    case 'SET_UPLOADER_URL':
      return { state, uploaderUrl: action.payload }
    default:
      return state
  }
}

export default ecommerce
