import React from 'react'
import FileList from './file-list'
import './file-attach.styles.scss'

type Props = {
  id: string
  name?: string
  label: string
  files?: File[]
  onChange(files: File[]): void
}

export default function FileAttach(props: Props) {
  const handleExclude = (file: File) => {
    const files = props.files?.filter((f) => f.name !== file.name) ?? []
    return props.onChange(files)
  }

  const handleSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const files = ev.target.files ? Array.from(ev.target.files) : []
    return props.onChange(files)
  }

  if (props.files && props.files.length > 0) {
    return <FileList files={props.files} onExclude={handleExclude} />
  }

  return (
    <div className="file-attach-container">
      <label className="label" htmlFor={props.id}>
        {props.label}
      </label>
      <input
        className="input"
        id={props.id}
        name={props.name ?? props.id}
        type="file"
        multiple={false}
        onChange={handleSelect}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </div>
  )
}
