import React from 'react'
import './selector.styles.scss'

type Props = {
  label?: string
  id: string
  values: { label: string; key: string }[]
  selected: string
  onSelect(key: string): void
}

export default function Selector(props: Props) {
  return (
    <div className="selector-container">
      <label className="label" htmlFor={props.id}>
        {props.label}
      </label>
      <select
        className="selector"
        id={props.id}
        value={props.selected}
        onChange={(ev) => props.onSelect(ev.target.value)}
      >
        <option className="option">Selecione</option>
        {props.values.map(({ label, key }) => (
          <option className="option" key={key} value={key}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}
