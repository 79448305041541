import React from 'react'
import FileListItem from './file-list-item'
import './file-list.styles.scss'

type Props = {
	files: File[],
	onExclude(file: File): void
}

export default function FileList(props: Props) {
	return (
		<ul className="file-list">
			{props.files.map((file) => (
				<FileListItem key={file.name} file={file} onExclude={props.onExclude} />
			))}
		</ul>
	)
}