import React from 'react'
import { Provider } from 'react-redux'
import PageRouter from './pages/page-router'
import { create } from './store/store'

import './app.styles.scss'

export default function App() {
  const store = create()

  return (
    <Provider store={store}>
      <PageRouter />
    </Provider>
  )
}
