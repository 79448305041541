import { all, takeLatest, debounce } from 'redux-saga/effects'
import { asyncGetConnectors } from './connectors-sagas'
import { asyncPostEcommerce, asyncPostEcommerceFile } from './ecommerces-sagas'

export default function * rootSaga() {
  yield all([
    takeLatest('ASYNC_GET_CONNECTORS', asyncGetConnectors),
    takeLatest('ASYNC_POST_ECOMMERCE', asyncPostEcommerce),
    takeLatest('ASYNC_ECOMMERCE_UPLOAD_FILE', asyncPostEcommerceFile)
  ])
}
