import React from 'react'
import './input.styles.scss'

type Props = {
  label: string
  id: string
  name?: string
  value: string
  onChange(value: string): void
}

export default function Input(props: Props) {
  return (
    <div className="input-container">
      <label className="label" htmlFor={props.id}>
        {props.label}
      </label>
      <input
        className="input"
        name={props.name ?? props.id}
        id={props.id}
        value={props.value}
        type="text"
        onChange={(ev) => props.onChange(ev.target.value)}
      />
    </div>
  )
}
