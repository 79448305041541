import React, { useState } from 'react'
import Input from './input'
import './form.styles.scss'

type Props = {
  integration: string
  fields: { key: string; label: string }[]
  onSubmit(payload: { [k: string]: string }): void
}

export default function ConnectorForm(props: Props) {
  const [ecommerce, setEcommerce] = useState<any>({})
  const [customFields, setCustomFields] = useState<any>({})

  const submit = (ev: React.FormEvent) => {
    ev.preventDefault()
    ev.stopPropagation()

    const payload = {
      ...ecommerce,
      integration: props.integration,
      custom_fields: Object.keys(customFields).map((field) => ({
        key: field,
        value: customFields[field]
      }))
    }

    props.onSubmit(payload)
  }

  if (!props.fields || props.fields.length === 0) return <></>

  return (
    <form className="form-container" onSubmit={submit}>
      <Input
        label="Nome"
        id="ecommerce-name"
        value={ecommerce.name ?? ''}
        onChange={(name) => setEcommerce({ ...ecommerce, name })}
      />

      <Input
        label="E-mail"
        id="ecommerce-email"
        value={ecommerce.email ?? ''}
        onChange={(email) => setEcommerce({ ...ecommerce, email })}
      />

      {props.fields.map((field) => (
        <Input
          key={field.key}
          label={field.label}
          id={field.key}
          value={customFields[field.key] ?? ''}
          onChange={(value) => setCustomFields({ ...customFields, [field.key]: value })}
        />
      ))}

      <div className="form-actions">
        <button className="button primary" id="form-submit-button" type="submit">
          Cadastrar
        </button>
      </div>
    </form>
  )
}
