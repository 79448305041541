import React, { useEffect, useState } from 'react'

import './page.styles.scss'
import { getConnectors, setSelectedConnector } from '../store/actions/connectos'
import { connect } from 'react-redux'
import { arrayOf, func } from 'prop-types'
import { Connector } from '../types/connector'
import ConnectorForm from '../components/connector-form'
import Selector from '../components/selector'
import { postEcommerce } from '../store/actions/ecommerces'

export function RegisterEcommercePage(props) {
  const [selected, setSelected] = useState(undefined)

  const handleSelect = (connectorId) => {
    setSelected(connectorId)
    props.selectConnector(connectorId)
  }

  const submit = (payload) => {
    props.savePlatform(payload)
  }

  useEffect(() => {
    props.getConnectors()
  }, [])

  return (
    <div className="page">
      <div className="content">
        <h1 className="page-title">Cadastro de E-commerce</h1>

        {props.connectors && (
          <Selector
            label="Selecione a plataforma"
            id="platform-selector"
            values={props.connectors}
            selected={selected}
            onSelect={handleSelect}
          />
        )}

        {props.selectedConnector && (
          <ConnectorForm
            integration={props.selectedConnector.name}
            fields={props.selectedConnector.custom_fields}
            onSubmit={submit}
          />
        )}
      </div>
    </div>
  )
}

RegisterEcommercePage.propTypes = {
  selectedConnector: Connector,
  connectors: arrayOf(Connector),
  getConnectors: func,
  selectConnector: func,
  savePlatform: func
}

const mapState = (state) => ({
  selectedConnector: state.connectors.selected,
  connectors: state.connectors.connectors
    ? state.connectors.connectors.map((conn) => ({
        key: conn.id,
        label: conn.name
      }))
    : []
})

const mapDispatch = (dispatch) => ({
  getConnectors: () => dispatch(getConnectors()),
  selectConnector: (connectorId) => dispatch(setSelectedConnector(connectorId)),
  savePlatform: (payload) => dispatch(postEcommerce(payload))
})

export default connect(mapState, mapDispatch)(RegisterEcommercePage)
